import React, { useState, useEffect, useRef } from "react";

import "./ui.css";

const girlColors = [
  "#f8aeb1",
  "#b897c5",
  "#f8aeb1",
  "#b897c5",
  "#f8aeb1",
  "#b897c5",
];
const boyColors = [
  "#79c5a9",
  "#77d0e8",
  "#79c5a9",
  "#77d0e8",
  "#79c5a9",
  "#77d0e8",
];
const rainBow = [
  "#f8aeb1",
  "#fece78",
  "#ef7f7f",
  "#77d0e8",
  "#79c5a9",
  "#b897c5",
];
const UI = ({ imageSet,setImageSet,imagesArr }) => {
  const [quantity, setQuantity] = useState(1);
  const [colorGrp, setColorGrp] = useState(boyColors);
  const [isMobile, setIsMobile] = useState(false);
  const [cost, setCost] = useState(`$${imageSet.price}.00 USD`);
  const canvas = useRef();
  const [ln1, setLn1] = useState("ABBY");
  const [ln2, setLn2] = useState("");
  const [overlayArr, setOverlayArr] = useState([]);
  const textBoxArr = [0, 1, 2];
  const yOffset = 483.5;
  const xOffset = 63.5;
  const xOffsetT = xOffset + 20;
  const boxWidth = 405;
  const boxHeight = 157;
  const xSpacing = 449;
  const ySpacing = 428;
  const row = 2;
  const col = 3;

  let sf;
  useEffect(() => {
    console.log(imageSet.price);
    if (window.innerWidth < 768) {
      setIsMobile(true);
      
    } else {
      setIsMobile(false);
    }
    let context = canvas.current.getContext("2d");

    let background = new Image();
    background.onload = () => {
      sf = isMobile
        ? window.innerWidth / background.width
        : (window.innerWidth * 0.6) / background.width;
      canvas.current.width = isMobile
        ? window.innerWidth
        : window.innerWidth * 0.6;
      canvas.current.height = background.height * sf;
      //console.log(sf);
      context.drawImage(
        background,
        0,
        0,
        canvas.current.width,
        background.height * sf
      );
      context.imageSmoothingEnabled = false;
      drawTextBoxes();
      drawText();
      drawWaterMark();
    };

    background.src = imageSet.url;
  }, [imageSet, colorGrp, ln1, ln2, isMobile]);
  const displayMoney = (integer)=>{return `$${integer}.00 USD`};
  const drawTextBoxes = () => {
    let context = canvas.current.getContext("2d");
    let n = 0;

    for (let i = 0; i < row; i++) {
      for (let j = 0; j < col; j++) {
        context.fillStyle = colorGrp[n];
        context.beginPath();
        context.roundRect(
          xOffset * sf + j * sf * xSpacing,
          yOffset * sf + i * sf * ySpacing,
          boxWidth * sf,
          boxHeight * sf,
          [20]
        );

        context.fill();
        n++;
      }
    }
  };
  const drawText = () => {
    let context = canvas.current.getContext("2d");
    context.fillStyle = "black";

    for (let i = 0; i < row; i++) {
      for (let j = 0; j < col; j++) {
        context.font = "bold 40px Arial";
        context.textAlign = "center";
        let textH = context.measureText(ln1).height;
        context.fillText(
          ln1,
          (xOffset + boxWidth / 2) * sf + j * sf * xSpacing,
          (yOffset + 20) * sf + i * sf * ySpacing + boxHeight * sf * 0.5,
          (boxWidth - 40) * sf,
          boxHeight * sf
        );
      }
    }
  };
  const drawWaterMark = () => {
    let context = canvas.current.getContext("2d");
    context.rotate((-45 * Math.PI) / 180);
    context.fillStyle = "rgba(255,0,0,.25)";
    context.font = isMobile ? "Bold 60px Arial" : "Bold 150px Arial";
    isMobile
      ? context.fillText(
          "PREVIEW",
          10,
          canvas.current.height,
          canvas.current.width,
          canvas.current.height
        )
      : context.fillText(
          "PREVIEW ONLY",
          50,
          canvas.current.height,
          canvas.current.width,
          canvas.current.height
        );
  };
  const createCanvas = () => {
    return <canvas ref={canvas} />;
  };
  const createStyle = (r, c) => {
    return {
      position: "relative",
      top: yOffset + r * (boxHeight + ySpacing) + "rem",
      left: xOffset + c * (boxWidth + xSpacing) + "rem",
      width: boxWidth + "rem",
      height: boxHeight + "rem",
    };
  };
  const createOverlay = () => {
    let rowArr = [];
    for (let i = 0; i < row; i++) {
      for (let j = 0; j < col; j++) {
        return (
          <div style={createStyle(i, j)} key={i + j}>
            <div className="ln1">{ln1}</div>
            <div className="ln2">{ln2}</div>
          </div>
        );
      }
    }
  };
  const overlay = {
    position: "absolute",
    top: yOffset + "rem",
    left: xOffset + "rem",
    width: boxWidth + "rem",
  };
  const adjustQuantity = (str) => {
    let num = quantity;
    switch (str) {
      case "add":
        setQuantity(quantity + 1);
        num++        
        break;
      case "minus":
        if (quantity > 1) setQuantity(quantity - 1);
        if(num>1)num--;
        break;
      default:
        break;
    }
    setCost(displayMoney(num*imageSet.price));
  };
  function sendLOTS(e){
    e.preventDefault()
    console.log("SENDING TO LOTS")
  }
  return (
    <section>
      <div className="stage">{createCanvas()}</div>
      <div className="controls">
        <div className="control_item">
          <label>Choose a Background</label>
          <select
          className="control_item"
            onChange={(e) => {
              setImageSet(imagesArr[e.target.value]);
              console.log(e.target.value);
            }}
          >
            
            <option value={0}>{imagesArr[0].name}</option>
            <option value={1}>{imagesArr[1].name}</option>
            <option value={2}>{imagesArr[2].name}</option>
            <option value={3}>{imagesArr[3].name}</option>
          </select>
        </div>        
        
        <div className="control_item">
          <label>Foreground Text</label>
          <input
            type="text"
            value={ln1}
            onChange={(e) => setLn1(e.target.value.toUpperCase())}
          />
        </div>
        {/* <div className="control_item">
          <label>Line 2 (optional)</label>
          <input
            type="text"
            value={ln2}
            onChange={(e) => setLn2(e.target.value)}
          />
        </div> */}
        <div className="control_item">
          <label>Box Color</label>
          <div className="color_boxes">
            <div
              className="box"
              onClick={() => {
                setColorGrp(boyColors);
                console.log("SETTING BOY");
              }}
            >
              Boy
            </div>
            <div
              className="box"
              onClick={() => {
                setColorGrp(girlColors);
                console.log("SETTING GIRL");
              }}
            >
              Girl
            </div>
            <div
              className="box"
              onClick={() => {
                setColorGrp(rainBow);
              }}
            >
              Rainbow
            </div>
          </div>
        </div>

        <div className="price_control">
        <div className="price">{cost}</div>
        <div className="control_item quantity">
          <label>Quantity</label>
          <div className="quantity_box">
            <div className="add" onClick={() => adjustQuantity("add")}>
              +
            </div>
            <div className="value">{quantity}</div>
            <div className="minus" onClick={() => adjustQuantity("minus")}>
              -
            </div>
          </div>
        </div>        
        </div>
        <div className="add_to_cart">
            <button className="add_to_cart_btn" onClick={sendLOTS}>Add To Cart</button>
        </div>
      </div>
    </section>
  );
};

export default UI;
