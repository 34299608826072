import React from 'react'
import { UI } from './components'


const Main = ({imageSet, setImageSet, imagesArr}) => {
  return (
    <UI imageSet={imageSet} setImageSet={setImageSet} imagesArr={imagesArr}/>
  )
}

export default Main