import "./App.css";
import { Main } from "./pages";
import React, {useState} from "react";
import { Navbar } from "./pages/components";
import animals1 from './pages/img/animals.png'
import kawai1 from './pages/img/kawai.png'
import chess from './pages/img/chess.png'
import dogs from './pages/img/dogs.png'
const imagesArr = [
  {
    id:1,
    name:'Animals 1',
    price:11,
    url:animals1
  },
  {
    id:2,
    name:'Kawai 1',
    price:10,
    url:kawai1
  },
  {
    id:3,
    name:'Chess',
    price:12,
    url:chess
  },
  {
    id:4,
    name:'Dogs',
    price:13,
    url:dogs
  },
]
function App() {
  const [imageSet, setImageSet] = useState(imagesArr[0])
  console.log(animals1)
  return (
    <div className="page">
      {/* <Navbar setImageSet={setImageSet} imagesArr={imagesArr}/> */}
      <Main imageSet= {imageSet}  setImageSet={setImageSet} imagesArr={imagesArr}/>
    </div>
  );
}

export default App;
