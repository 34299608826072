import React, {useState} from 'react'
import './navbar.css'
const Navbar = ({setImageSet, imagesArr}) => {
const [selection, setSelection] = useState('');
const [show, setShow] = useState(false)
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
        <nav>
            <div className='branding'>CustomStickerz</div>
            <div className='hamburger_menu' onClick={()=>{setShow(!show)}}>
                <div className='h_line'></div>
                <div className='h_line'></div>
                <div className='h_line'></div>
            </div>
            <div className='navlinks'>
                <ul>
                    <li>Home</li>
                    <select onChange={e=>{setImageSet(imagesArr[e.target.value]); console.log(e.target.value)}}>
                            <option value={0}>Products</option>
                            <option value={0}>{imagesArr[0].name}</option>
                            <option value={1}>{imagesArr[1].name}</option>
                            <option value={2}>{imagesArr[2].name}</option>
                            <option value={3}>{imagesArr[3].name}</option>
                    </select>
                    <li>Cart</li>
                </ul>
            </div>
        </nav>
        <div className='mobile_links' style={show?{display:'block'}:{display:'none'}}>
        <ul>
                    <li>Home</li>
                    <select onChange={e=>{setImageSet(imagesArr[e.target.value]); console.log(e.target.value)}}>
                            <option value={0}>Products</option>
                            <option value={0}>{imagesArr[0].name}</option>
                            <option value={1}>{imagesArr[1].name}</option>
                            <option value={2}>{imagesArr[2].name}</option>
                            <option value={3}>{imagesArr[3].name}</option>
                    </select>
                    <li>Cart</li>
                </ul>
        </div>
    </div>
  )
}

export default Navbar